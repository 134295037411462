import Typewriter from 'typewriter-effect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

import dev from '../assets/img/hero/dev.png'

const Hero = () => {
    return (
        <section id='hero'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xl-4 text-center text-xl-start intro mt-4'>
                        <h4>HELLO</h4>
                        <span className='right-dash'>MY NAME IS</span>
                        <Typewriter
                            options={{
                                strings: '<h1>Danny Santino</h1>',
                                autoStart: true,
                                loop: true,
                                pauseFor: 4000
                            }}
                        />
                        <p className='lead mt-2'>
                            I am a full stack web developer passionate about writing clean,
                            DRY & efficient code, and also delivering exceptional user experience.
                        </p>
                        <div className='action mt-4'>
                            <a href='#contact' className='link-btn btn-gradient'>HIRE ME</a>
                            <a
                                href='https://drive.google.com/file/d/1Ux7-6OjL8IbQiH_udrI9N3BdFwmENvLJ/view?usp=sharing'
                                rel='noreferrer'
                                target='_blank'
                            >
                                <span className='file-icon me-3'>
                                    <FontAwesomeIcon icon={faFileArrowDown} fixedWidth />
                                </span>
                                <span>DOWNLOAD CV</span>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-8 img mt-4 mt-xl-0'>
                        <img
                            src={dev}
                            alt='illustration of a web developer with hovering icons'
                            title='Developer vector created by upklyak - https://www.freepik.com/vectors/developer'
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
