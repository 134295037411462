export const alertUser = (type, message) => {
    document.getElementById('alert').innerHTML = `
        <div class='${type} alert alert-dismissible fade show' role='alert'>
            ${type === 'success'
            ? '<i class="bi bi-check-circle-fill me-2"></i>'
            : '<i class="bi bi-exclamation-triangle-fill me-2"></i>'}
            <p class='mb-0'>${message}</p>
            <button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button>
        </div>
    `
}