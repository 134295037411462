export const projectData = [
    {
        name: 'Cara Store',
        media: 'gif',
        src: 'Cara Store.gif',
        liveLink: 'https://caraonline.netlify.app/',
        repoLink: 'https://github.com/dannysantino/cara-online-store.git',
        filters: 'full-stack express',
        logoSet: 'cara'
    },
    {
        name: 'Cara Store - Dashboard',
        media: 'gif',
        src: 'Cara Store - Admin.gif',
        liveLink: 'https://caraonlineadmin.netlify.app/',
        repoLink: 'https://github.com/dannysantino/cara-online-store/tree/main/admin',
        filters: 'full-stack express',
        logoSet: 'caraAdmin'
    },
    {
        name: 'Rymo Shopping Cart',
        media: 'gif',
        src: 'Rymo Shopping Cart.gif',
        liveLink: 'https://rymostore.netlify.app/',
        repoLink: 'https://github.com/dannysantino/rymo-online-store.git',
        filters: 'full-stack express',
        logoSet: 'rymo'
    },
    {
        name: 'Tech Educa',
        media: 'png',
        src: 'Tech Educa - Mockup.png',
        liveLink: 'https://dannysantino.github.io/techeduca/',
        repoLink: 'https://github.com/dannysantino/techeduca.git',
        filters: 'front-end',
        logoSet: 'educa'
    },
    {
        name: 'Novabuzz Social Media',
        media: 'gif',
        src: 'Novabuzz - Mockup.gif',
        liveLink: 'https://novasocial.netlify.app/',
        repoLink: 'https://github.com/dannysantino/website-project-social-media-app.git',
        filters: 'full-stack apollo',
        logoSet: 'novabuzz'
    },
    {
        name: 'The Road',
        media: 'png',
        src: 'The Road - Mockup.png',
        liveLink: 'https://dannysantino.github.io/the-road/',
        repoLink: 'https://github.com/dannysantino/the-road.git',
        filters: 'front-end',
        logoSet: 'road'
    }
];
