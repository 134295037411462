import Navbar from './components/Navbar.jsx'
import Hero from './components/Hero.jsx'
import Stack from './components/Stack.jsx'
import About from './components/About.jsx'
import Projects from './components/Projects.jsx'
import Contact from './components/Contact.jsx'
import Footer from './components/Footer.jsx'

import './App.css'

function App() {
  return (
    <>
      <Navbar />
      <main>
        <Hero />
        <Stack />
        <About />
        <Projects />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default App;
