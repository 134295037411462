import { useEffect, useRef, useState } from 'react'
import Isotope from 'isotope-layout'
import imagesLoaded from 'imagesloaded'

import { projectData } from '../utils/projectData'
import Project from './Project'

const Projects = () => {
    const filterBtns = [
        ['* active', 'All'],
        ['.full-stack', 'Full Stack'],
        ['.express', 'Express'],
        ['.apollo', 'Apollo'],
        ['.front-end', 'Front End']
    ];

    const iso = useRef();
    const [filter, setFilter] = useState('*');

    const handleFilter = e => {
        const target = e.target.classList[1];
        Array.from(document.querySelectorAll('.btn-filter')).forEach(b => {
            if (!b.classList.contains(target) && b.classList.contains('active')) {
                b.classList.remove('active');
            } else if (b.classList.contains(target)) {
                b.classList.add('active');
            }
        });
        setFilter(target);
    }

    useEffect(() => {
        iso.current = new Isotope('.project-tiles', {
            itemSelector: '.project',
            layoutMode: 'fitRows'
        });

        return () => iso.current.destroy();
    }, []);

    useEffect(() => {
        imagesLoaded('.project-tiles', () => iso.current.arrange({ filter }));
    }, [filter]);

    return (
        <section id='projects'>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-lg-10 offset-lg-1 text-center'>
                        <h2>My Projects</h2>
                        <p>A showcase of some of the major web projects I have developed.</p>
                        <div className='filters'>
                            {filterBtns.map(e => (
                                <button
                                    className={`btn-filter ${e[0]}`}
                                    onClick={handleFilter}
                                    key={e[1]}
                                >
                                    {e[1]}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='row project-tiles'>
                    {projectData.map(e => (
                        <Project
                            name={e.name}
                            media={e.media}
                            src={e.src}
                            liveLink={e.liveLink}
                            repoLink={e.repoLink}
                            filters={e.filters}
                            logoSet={e.logoSet}
                            key={e.name}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Projects
