export const projectLogos = {
    cara: [
        'mongodb',
        'express',
        'react',
        'node-js',
        'bootstrap',
        'redux',
        'axios',
        'jwt',
        'cloudinary',
        'stripe'
    ],
    caraAdmin: [
        'mongodb',
        'express',
        'react',
        'node-js',
        'bootstrap',
        'redux',
        'axios',
        'jwt',
        'cloudinary',
        'recharts',
        'material-ui'
    ],
    rymo: [
        'mongodb',
        'express',
        'react',
        'node-js',
        'bootstrap',
        'redux',
        'axios'
    ],
    educa: [
        'html5',
        'css3',
        'font-awesome',
        'jquery'
    ],
    novabuzz: [
        'mongodb',
        'apollo',
        'react',
        'node-js',
        'semantic-ui',
        'graphql',
        'jwt'
    ],
    road: [
        'html5',
        'css3',
        'js',
        'google-fonts'
    ]
}