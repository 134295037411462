import developers from '../assets/img/about/developers.png'

const About = () => {
    return (
        <section id='about' className='py-72'>
            <div className='container'>
                <div className='row gx-xl-5'>
                    <div className='col-lg-6'>
                        <img
                            src={developers}
                            alt='illustration of web developers with floating icons'
                            title='Image by upklyak on https://www.freepik.com/'
                        />
                    </div>
                    <div className='col-lg-6'>
                        <h2 className='mt-5 mt-lg-0 mt-xl-3 mt-xxl-5'>A Little Bit About Me...</h2>
                        <p>
                            I am a certified and highly skilled developer with valuable experience designing
                            and developing many web projects from social media to e-commerce
                            apps. I have a fondness for the MERN stack, however, I also have experience,
                            and enjoy, working with several other technology applications like&nbsp;
                            <b>Apollo Server</b>, <b>GraphQL</b>, <b>AWS</b>, etc.
                        </p>
                        <p>
                            I am currently looking to take on a web developer role and
                            also collabolrate on exciting projects. Please, do not hesitate to reach out!
                        </p>
                        <p>
                            The technologies I work with include: <br />
                            <b>
                                HTML • CSS • JavaScript • MongoDB • Express • React • NodeJS • Bootstrap • MaterialUI • SemanticUI •
                                jQuery • Redux • Axios • Stripe • Cloudinary • Apollo-Server • GraphQL • Recharts
                            </b>
                        </p>
                        <a href='#contact' className='link-btn btn-gradient mt-4'>CONTACT ME</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
