import { useEffect } from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Navbar = () => {
    const navs = ['hero', 'stack', 'about', 'projects', 'contact'];

    const handleActive = () => {
        Array.from(document.querySelectorAll('section')).forEach(s => {
            const navLink = document.querySelector(`.${s.getAttribute('id')}`),
                sY = window.scrollY,
                o = s.offsetTop - (.15 * window.innerHeight),
                h = s.clientHeight;
            sY >= o && sY <= (o + h)
                ? navLink.classList.add('active')
                : navLink.classList.remove('active');
        });
    }

    const handleCollapse = () => {
        const toggler = document.querySelector('.navbar-toggler');
        const navbarNav = document.querySelector('.navbar-collapse');
        if (window.innerWidth < 992 && !toggler.classList.contains('collapsed')) {
            toggler.classList.add('collapsed');
            navbarNav.classList.remove('show');
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleActive);
        return () => window.removeEventListener('scroll', handleActive);
    }, []);

    return (
        <>
            <nav className='navbar navbar-expand-lg fixed-top shadow'>
                <div className='container'>
                    <a className='navbar-brand' href='/'>DS</a>
                    <button
                        className='navbar-toggler'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarNav'
                        aria-controls='navbarNav'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span>
                            <FontAwesomeIcon icon={faBars} />
                        </span>
                    </button>
                    <div className='collapse navbar-collapse' id='navbarNav'>
                        <ul className='navbar-nav ms-auto'>
                            {navs.map(e => (
                                <li className='nav-item' key={e}>
                                    <a
                                        className={`nav-link ${e === 'hero' ? e + ' active' : e}`}
                                        href={'#' + e}
                                        onClick={handleCollapse}
                                    >
                                        {e === 'hero' ? 'home' : e}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>
            <div className='d-inline-block' id='alert'></div>
        </>

    )
}

export default Navbar
