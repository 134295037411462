import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faServer } from '@fortawesome/free-solid-svg-icons'
import { faReact, faNodeJs } from '@fortawesome/free-brands-svg-icons'

const Stack = () => {
    const stack = [
        {
            name: 'MongoDB',
            type: 'Database',
            icon: faDatabase,
            className: ['mongo-db', 'mb-4 mb-xl-0']
        },
        {
            name: 'Express',
            type: 'Server',
            icon: faServer,
            className: ['express', 'mb-4 mb-xl-0']
        },
        {
            name: 'ReactJS',
            type: 'User Interface',
            icon: faReact,
            className: ['react', 'mb-4 mb-sm-0']
        },
        {
            name: 'NodeJS',
            type: 'Runtime',
            icon: faNodeJs,
            className: ['node', 'mb-0']
        }
    ];

    return (
        <section id='stack' className='py-72'>
            <div className='container-fluid'>
                <div className='row mx-sm-3'>
                    {stack.map(e => (
                        <div className={`col-sm-6 col-xl-3 ${e.className[1]}`} key={e.name}>
                            <div className={`wrapper ${e.className[0]}`}>
                                <span>
                                    <FontAwesomeIcon icon={e.icon} size='3x' />
                                </span>
                                <div className='desc'>
                                    <h4>{e.name}</h4>
                                    <p>{e.type}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Stack
