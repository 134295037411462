const Footer = () => {
    return (
        <footer>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 offset-lg-2 text-center p-3'>
                        <p className='mb-0'>&copy; 2022 | <b>Danny Santino</b></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
