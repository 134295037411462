import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { alertUser } from '../utils/alertUser'

const Contact = () => {
    const socials = [
        [faLinkedin, 'linkedin.com/in/dannysantino'],
        [faGithub, 'github.com/dannysantino'],
        [faTwitter, 'twitter.com/thedannysantino'],
        [faInstagram, 'instagram.com/thedannysantino']
    ];

    const inputs = ['name', 'email', 'subject', 'message'];

    const [loading, setLoading] = useState(false);
    const [mail, setMail] = useState({});

    const handleChange = e => {
        setMail(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const handleSubmit = async e => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
        } else {
            try {
                setLoading(true);
                const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mail/send`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(mail)
                });
                const data = await res.json();
                setLoading(false);
                if (res.ok) {
                    alertUser('success', data);
                    form.classList.remove('was-validated');
                    document.querySelector('form').reset();
                } else {
                    if (res.status === 400) {
                        throw new Error(data);
                    }
                    throw new Error('An error was encountered. Please try again.');
                }
            } catch (err) {
                setLoading(false);
                console.error(err);
                alertUser('error', err.message);
            }
        }
    }

    return (
        <section id='contact' className='py-72'>
            <div className='container'>
                <div className='row mb-4'>
                    <div className='col-lg-8 offset-lg-2 text-center'>
                        <h2 className='left-dash right-dash'>Contact Me</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8'>
                        <p className='lead mb-4'>Fill the form below and I'll get back to you in no time!</p>
                        <form
                            className='row'
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            {inputs.map(e => (
                                <div className='col-lg-10 mb-3' key={e}>
                                    <label htmlFor={e} className='form-label'>{e}</label>
                                    {e !== 'message'
                                        ? <input
                                            type='text'
                                            className='form-control'
                                            id={e}
                                            name={e}
                                            onChange={handleChange}
                                            required
                                        />
                                        : <textarea
                                            className='form-control'
                                            id={e}
                                            name={e}
                                            cols='30'
                                            rows='10'
                                            onChange={handleChange}
                                            required
                                        >
                                        </textarea>
                                    }
                                </div>
                            ))}
                            <div className='col-8 mt-4'>
                                <button
                                    type='submit'
                                    className='btn-gradient'
                                    disabled={loading}
                                >
                                    {loading &&
                                        <span
                                            className='spinner-border spinner-border-sm me-2'
                                            role='status'
                                            aria-hidden='true'
                                        />
                                    }
                                    SEND
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className='col-lg-4 contacts'>
                        <p className='lead'>You can also reach me via any of the following:</p>
                        <div className='phone mb-3'>
                            <FontAwesomeIcon
                                icon={faPhone}
                                size='lg'
                                fixedWidth
                            />
                            <span
                                className='text-secondary mx-3'
                                id='phone'
                            >
                                {process.env.REACT_APP_PHONE}
                            </span>
                        </div>
                        <div className='email'>
                            <FontAwesomeIcon
                                icon={faEnvelopeOpenText}
                                size='lg'
                                fixedWidth
                            />
                            <a
                                href='mailto:dev@dannysantino.com'
                                id='mail'
                                className='mx-3'
                            >
                                {process.env.REACT_APP_EMAIL}
                            </a>
                        </div>
                        <div className='socials mt-4'>
                            <h4>Socials</h4>
                            {socials.map(e => (
                                <a
                                    href={`https://${e[1]}`}
                                    rel='noreferrer'
                                    target='_blank'
                                    key={e[1]}
                                >
                                    <FontAwesomeIcon
                                        icon={e[0]}
                                        size='2x'
                                        fixedWidth
                                    />
                                </a>
                            ))}
                        </div>
                        {/* color gradient for instagram svg icon */}
                        <svg width='0' height='0'>
                            <radialGradient id='ig' r='125%' cx='30%' cy='107%'>
                                <stop stopColor='#ffd600' offset='0.15' />
                                <stop stopColor='#ff7a00' offset='0.3' />
                                <stop stopColor='#ff0069' offset='0.45' />
                                <stop stopColor='#d300c5' offset='0.6' />
                                <stop stopColor='#7638fa' offset='0.9' />
                            </radialGradient>
                        </svg>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
