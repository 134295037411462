import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

import { importAssets } from '../utils/importAssets'
import { projectLogos } from '../utils/projectLogos'

const Project = ({ name, media, src, liveLink, repoLink, filters, logoSet }) => {
    const projects = importAssets(require.context('../assets/img/projects', false, /\.(png|gif|mp4)$/));
    const logos = importAssets(require.context('../assets/img/logos', false, /\.png/));

    return (
        <div className={`col-md-6 col-xl-4 project ${filters}`}>
            <div className='wrapper'>
                <a
                    href={liveLink}
                    rel='noreferrer'
                    target='_blank'
                >
                    <img src={projects[src]} alt={`${name} ${media}`} />
                </a>
                <div className='details'>
                    <div className='title mt-3'>
                        <a
                            href={liveLink}
                            rel='noreferrer'
                            target='_blank'
                        >
                            <h5>{name}</h5>
                        </a>
                        <a
                            href={repoLink}
                            rel='noreferrer'
                            target='_blank'
                        >
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                    </div>
                    <hr />
                    <div className='logos'>
                        {projectLogos[logoSet].map(e => (
                            <img src={logos[`${e}.png`]} alt={`${e} logo`} title={e} key={e} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Project
